const MENU_ITEMS = [
  {
    key: 'home',
    label: 'Home',
    url: '/',
    isTitle: false,
    icon: 'bi bi-house',
  },
];

export default MENU_ITEMS;
