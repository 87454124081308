// @flow
import React from 'react';
import Routes from './routes/Routes';
import './assets/scss/dashboard.scss';

/**
 * Main app component
 */
function App() {
  return <Routes />;
}

export default App;
