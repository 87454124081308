import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Loader from '../components/Loader';
import DelayedRedirect from '../components/DelayedRedirect';

// lazy load all the views

// pages
const Login = React.lazy(() => import('../pages/account/Login'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const StandardError = React.lazy(() => import('../pages/error/StandardError'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Homepage = React.lazy(() => import('../pages/homepage'));


// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: Homepage,
  route: PrivateRoute,
};

const otherPublicRoutes = [
  {
    path: '/login',
    exact: true,
    component: Login,
    route: Route,
  },
  {
    path: '/callback',
    exact: true,
    component: () => {
      window.location = '/';
      return null;
    },
    route: Route,
  },
  {
    path: '/logout',
    exact: true,
    component: () => {
      window.location = '/';
      return null;
    },
    route: Route,
  },
  {
    path: '/error',
    name: 'Error',
    component: StandardError,
    route: Route,
  },
  {
    path: '/error-404',
    name: 'Error - 404',
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: ServerError,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute];
const publicRoutes = [...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes,
};
