import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import { configureStore } from './redux/store';

if (!window._babelPolyfill) {
   require('babel-polyfill');
}

// Element Container
let elementContainer = document.getElementById('dms-one-element-container');

if (!elementContainer) {
  elementContainer = document.createElement('div');
  elementContainer.id = 'dms-one-element-container';
  document.body.appendChild(elementContainer);
}

ReactDOM.render(
  <Auth0ProviderWithHistory>
    <Provider store={configureStore({})}>
      <App />
    </Provider>
  </Auth0ProviderWithHistory>,
  document.getElementById('app'),
);
