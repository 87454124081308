import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";

import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import Loader from "../components/Loader";

function Routes(props) {
  const { layout } = useSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
    case layoutConstants.LAYOUT_HORIZONTAL:
      layoutCls = HorizontalLayout;
      break;
    case layoutConstants.LAYOUT_DETACHED:
      layoutCls = DetachedLayout;
      break;
    case layoutConstants.LAYOUT_FULL:
      layoutCls = FullLayout;
      break;
    default:
      layoutCls = VerticalLayout;
      break;
    }
    return layoutCls;
  };

  const Layout = getLayout();
  const auth0 = useAuth0();

  if (auth0.isLoading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={publicProtectedFlattenRoutes.map((r) => r.path)}>
          <DefaultLayout {...props} layout={layout}>
            <Switch>
              {publicProtectedFlattenRoutes.map((route, index) => !route.children && (
                <route.route
                  key={index}
                  path={route.path}
                  roles={route.roles}
                  exact
                  component={route.component}
                />
              ))}
              <Redirect to="/error-404" />
            </Switch>
          </DefaultLayout>
        </Route>

        <Route path={authProtectedFlattenRoutes.map((r) => r.path)}>
          <Layout {...props} layout={layout} user={auth0.user}>
            <Switch>
              {authProtectedFlattenRoutes.map((route, index) => !route.children && (
                <route.route
                  key={index}
                  path={route.path}
                  roles={route.roles}
                  exact
                  component={route.component}
                />
              ))}
              <Redirect to="/error-404" />
            </Switch>
          </Layout>
        </Route>

      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
